// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // URL_SERVICE: 'http://129.213.50.143:8081/WSRestAPISucredi/EBSFEDEP/',
  // URL_SERVICE:'http://localhost:8080/WSRestAPISucredi/EBSFEDEP/',
  URL_SERVICE:'https://tspincsucredibe.ebsproductodigital.com/WSRestAPISucredi/EBSFEDEP/',
  URL_FTP: 'http://appswebpre.ebscreditodigital.com/FTP_PINC/FTP_REDES/'


// Construir para pruebas
  // ng build --output-hashing=all --prod --base-href /

  // código del pagaré

  // URL_FTP: 'http://localhost:9090/WSRestAPIProgresemos/',


  // URL_SERVICE:'http://localhost:8580/PINC/APIREST/apirest_pinc/WSRestAPIPinc/WSRestAPIPinc/src/java/com/co/ebssas/pinc/wsrestapi/WSRestCargaDatos.java/',
  // URL_FTP: 'http://localhost:8580/PINC/APIREST/apirest_pinc/WSRestAPIPinc/WSRestAPIPinc/src/java/com/co/ebssas/pinc/wsrestapi/WSRestCargaDatos.java/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
