import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { GestorSolPagareImpService } from 'src/app/services/ImplementacionServicios/gestor-sol-pagare-imp.service';
import { RespuestaGenerica } from '../../../Model/Response/RespuestaGenerica';
import { DialogComponent } from '../../pages/dialog/dialog.component';
import { ResponseDetalleFirmaPagare, firmas } from 'src/app/Model/Response/response-detalle-firma-pagare';
import { CargarTodosUsuariosImpService } from '../../../services/ImplementacionServicios/cargar-todos-usuarios-imp';
import { MessageService } from 'primeng/api';
import { CCiudad, PersonaCodeudor, PersonaRe } from '../../../Model/DTO/PersonaRegistry';
import { cCiudad, iTipoIde } from '../../../Model/Response/RespuestaPersona';
import { ResponseSolicitudPagare } from '../../../Model/Response/response-solicitud-pagare';
import { CargaDatosImpService } from 'src/app/services/ImplementacionServicios/carga-datos-imp';
import { RespuestaTipoPerfil, TipoPerfil } from 'src/app/Model/Response/RespuestaTipoPerfil';
import { RespuestaTipoIdent, TipoIdentificacion } from 'src/app/Model/Response/RespuestaTipoIdent';
import { SolicitudPagare } from 'src/app/Model/solicitud-pagare';
import { ResponseObtenerDocumento, ResponseObtenerMultiplesDocs } from 'src/app/Model/Response/response-obtener-documento';
import { ElementRef } from '@angular/core';
import { Ciudad, RespuestaCiudad } from 'src/app/Model/Response/response-ciudades';
import { TokenImpService } from 'src/app/Services/ImplementacionServicios/token-imp';
import { SeguridadClienteService } from 'src/app/Services/ConsumoServicios/seguridad-cliente.service';
import { RequestCrearPagare } from 'src/app/Model/request/request-crear-pagare';
import { HostListener } from '@angular/core';
@Component({
  selector: 'app-gestion-pagare',
  templateUrl: './gestion-pagare.component.html',
  styleUrls: ['./gestion-pagare.component.css'],
  providers: [MessageService]
})
export class GestionPagareComponent implements OnInit {

  mostrarTablaNormal = true;
  mostrarTablaNueva = false;
  constructor(
    private gestorSolPagareImpServie: GestorSolPagareImpService,
    private SpinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private cargarTodosUsuariosImpService: CargarTodosUsuariosImpService,
    private cargaDatosServiceImp: CargaDatosImpService,
    private elRef: ElementRef,
    private tokenServiceImp: TokenImpService,
    private seguridadService: SeguridadClienteService
  ) { }
  public verificacion: boolean;
  public dataSource: ResponseSolicitudPagare[];
  public isAsociado: boolean;
  public listaCiudades: Ciudad[];
  public infoPersona: PersonaRe = new PersonaRe();
  public displayModalInfoCodeudor: boolean;
  public displayModalFirmas: boolean;
  public informacion: boolean;
  public listFirmas: firmas[];
  public datosPersona: any;
  public tipoIndent: any;
  public tipoPerfil: number;
  public tipoPerfilCod: any[] = [];
  public tipoPerfilFind: number;
  public codeudores: any[] = [];
  public Identi: any;
  public cCiudad: number;
  public cCiudadCod: number[] = [];
  public codeudor:PersonaCodeudor = new PersonaCodeudor();
  public reqCodeudor: boolean;
  public ITipoIden: iTipoIde;
  public ciudad: cCiudad;
  public listPerfilCiente: TipoPerfil[];
  public listTipoIdent: TipoIdentificacion[];
  public listTipoIdentCod: TipoIdentificacion[];
  public txtFechaExpDoc: any;
  public validado = false;
  public personaCodeudor: any[] = [];
  public numPagare: number;
  ngOnInit() {
    this.verificacion = false;
    this.codeudores = [];
    this.displayModalInfoCodeudor = false;
    this.isAsociado = false;
    this.informacion = false;
    this.reqCodeudor = false;
    this.listPerfilCiente = null;
    this.personaCodeudor = [];
    this.cCiudadCod = [];
    this.tipoPerfilCod = [];
    this.listTipoIdent = null;
    this.tipoPerfilFind = null;
    this.tipoPerfil = null;
    this.numPagare = null;
    this.ITipoIden = { ICodigo: 0, CDescripcion: '', CEstado: '' };
    this.ciudad = {ICodigo: 0, CNombres: ''};
    this.infoPersona = { ICodigo: null, CIdentificacion: null, CNombres: null, CApellidos: null, CDireccionResidencia: null, CNumTelFijo: null, CNumCelular: null, CEmail: null, CGenero: null, CEstado: null, ITipoIdent: this.ITipoIden, CCiudad: this.ciudad };
    this.codeudor = { ICodigo: null, CIdentificacion: null, CNombres: null, CApellidos: null, CDireccionResidencia: null, CNumTelFijo: null, CNumCelular: null, CEmail: null, CGenero: null, CEstado: null, ITipoIdent: this.ITipoIden };
    this.cargarTableSolPagare();
    this.verificarAnchoPantalla();
  }

  reiniciarFormCodeudores(){
    if (this.reqCodeudor === false) {
      this.codeudores = [];
      this.ITipoIden = { ICodigo: 0, CDescripcion: '', CEstado: '' };
      this.tipoPerfilCod = [];
      this.cCiudadCod = [];
      this.personaCodeudor = [];
      this.codeudor = { ICodigo: null, CIdentificacion: null, CNombres: null, CApellidos: null, CDireccionResidencia: null, CNumTelFijo: null, CNumCelular: null, CEmail: null, CGenero: null, CEstado: null, ITipoIdent: this.ITipoIden };
    } else {
      this.messageService.add({ key: 'msgDiaglogValident', severity: 'warn', summary: 'Alerta!', detail: 'Solo podrá agregar máximo 3 codeudores' });
    }
  }

  cargarTableSolPagare() {
    this.SpinnerService.show('sp6');
    this.gestorSolPagareImpServie.cargarSolicitudesPagare().then(
      (response: any) => {
        if (response !== null && response !== undefined && response.length > 0) {
          this.dataSource = response;
          this.SpinnerService.hide('sp6');
          let tablaResponsive = this.elRef.nativeElement.querySelector('.p-datatable-responsive-demo');
          // tablaResponsive.classList.remove('p-datatable-responsive-demo');
          // tablaResponsive.classList.add('p-datatable-responsive');
        }
        else {
          this.SpinnerService.hide("sp6");
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Alerta!', detail: 'No hay solicitudes para cargar'});
        }
      }
    )
  }

  obtenerCiudades(){
    this.SpinnerService.show('sp6');
    this.cargaDatosServiceImp.obtenerCiudades()
    .then(
      (res: RespuestaCiudad) => {
        if (res != null && res.flag) {
          this.SpinnerService.hide('sp6');
          this.listaCiudades = res.listaCiudad;
        } else {
          this.SpinnerService.hide("sp6");
          this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un error al intentar obtener la información, intente de nuevo' });
        }
      }
    ).catch((error) => {
      this.SpinnerService.hide("sp6");
      this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un error de conexión, intente de nuevo' });
    });
  }

  agregarCodeudor(){
    this.codeudor.CNombres = this.codeudor.CNombres.toUpperCase();
    this.codeudor.CApellidos = this.codeudor.CApellidos.toUpperCase();
    this.codeudor.CDireccionResidencia = this.codeudor.CDireccionResidencia.toUpperCase();
    if (this.verificacion) {
      if (this.codeudores.length < 3) {
        this.codeudores.push(this.codeudor);
        this.enviarCodeudor(this.codeudor);
        let form = this.elRef.nativeElement.querySelector('#formInfoCodeudores');
        form.classList.remove('was-validated');
        this.messageService.add({key: 'msgDiaglogValident', severity: 'success', summary: '¡Excelente!', detail: 'Se agrego el codeudor ' + this.codeudor.CNombres + ' ' + this.codeudor.CApellidos });
        this.txtFechaExpDoc = null;
        this.verificacion = false;
        this.ITipoIden = { ICodigo: 0, CDescripcion: '', CEstado: '' };
        this.codeudor = { ICodigo: null, CIdentificacion: null, CNombres: null, CApellidos: null, CDireccionResidencia: null, CNumTelFijo: null, CNumCelular: null, CEmail: null, CGenero: null,CCiudad: null, TPerfil: null, CEstado: null, ITipoIdent: this.ITipoIden };
      } else {
        this.messageService.clear();
        this.ITipoIden = { ICodigo: 0, CDescripcion: '', CEstado: '' };
        this.codeudor = { ICodigo: null, CIdentificacion: null, CNombres: null, CApellidos: null, CDireccionResidencia: null, CNumTelFijo: null, CNumCelular: null, CEmail: null, CGenero: null,CCiudad: null, TPerfil: null, CEstado: null, ITipoIdent: this.ITipoIden };
        this.messageService.add({key: 'msgDiaglogValident', severity: 'warn', summary: 'Alerta!', detail: 'Ya existen ' + this.codeudores.length + ' codeudores para guardar' });
      }
    } else {
      this.txtFechaExpDoc = null;
      let form = this.elRef.nativeElement.querySelector('#formInfoCodeudores');
      form.classList.add('was-validated');
      this.messageService.add({key: 'msgDiaglogValident', severity: 'error', summary: 'Alerta!', detail: 'La fecha de expedición no es válida con el documento, intente de nuevo' });
    }
  }

  enviarCodeudor(codeudor: any){
      let nuevoCodeudor: PersonaRe = {
        ICodigo: codeudor.ICodigo,
        CIdentificacion: codeudor.CIdentificacion,
        CNombres: codeudor.CNombres,
        CApellidos: codeudor.CApellidos,
        CDireccionResidencia: codeudor.CDireccionResidencia,
        CNumTelFijo: codeudor.CNumTelFijo,
        CNumCelular: codeudor.CNumCelular,
        CEmail: codeudor.CEmail,
        CGenero: codeudor.CGenero,
        CEstado: codeudor.CEstado,
        ITipoIdent: codeudor.ITipoIdent,
        CCiudad: codeudor.CCiudad
      }
        this.cCiudadCod.push(codeudor.CCiudad);
        this.tipoPerfilCod.push(codeudor.TPerfil);
        this.personaCodeudor.push(nuevoCodeudor);
  }

  obtenerPerfilesClientes() {
    this.SpinnerService.show("sp6");
    this.cargaDatosServiceImp.tipoPerfilCliente().then(
      (response: RespuestaTipoPerfil) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide("sp6");
          this.listPerfilCiente = response.listaTipoPerfil;
        } else {
          this.SpinnerService.hide("sp6");
          this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un error al intentar obtener la información, intente de nuevo' });
        }
      }
    ), (error) => {
      this.SpinnerService.hide("sp6");
      this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un error de conexión, intente de nuevo' });
    }
  }

  obtenerTiposIdent() {
    this.SpinnerService.show("sp6");
    this.cargaDatosServiceImp.tiposIden().then(
      (response: RespuestaTipoIdent) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide("sp6");
          this.listTipoIdent = response.listaTipoIdent;
          this.listTipoIdentCod = response.listaTipoIdent;
        } else {
          this.SpinnerService.hide("sp6");
          this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un error al intentar obtener la información, intente de nuevo' });
        }
      }
    ).catch((error) => {
      this.SpinnerService.hide("sp6");
      this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un error de conexión, intente de nuevo' });
    });
  }

  actualizarEstado(xiIdProceso: string) {
    this.SpinnerService.show('sp6');
    this.gestorSolPagareImpServie.cargarEstadoPagare(xiIdProceso).then(
      (data: RespuestaGenerica) => {
        this.SpinnerService.hide('sp6');
        if (data.bRta === false) {
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Alerta!', detail: data.sMsj });
        } else {
          this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Éxito!', detail: data.sMsj });
          this.cargarTableSolPagare();
        }
      }
    ).catch((error) => {
      this.SpinnerService.hide('sp6');
      this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un error de conexión, intente de nuevo' });
    });
  }

  detalleFirmasPagare(xiIdProceso: string) {
    this.SpinnerService.show('sp6');
    this.gestorSolPagareImpServie.cargarDetalleFirmaDocumento(xiIdProceso).then(
      (data: ResponseDetalleFirmaPagare) => {
        this.SpinnerService.hide('sp6');
        if (data.bRta === false) {
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: 'Alerta!', detail: data.sMsg });
        } else {
          this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Éxito!', detail: data.sMsg });
          this.displayModalFirmas = true;
          this.listFirmas = data.firmas;
        }
      }
    ).catch((error)  => {
      this.SpinnerService.hide("sp6");
      this.messageService.add({ key: 'msgToast', severity: 'error', summary: 'error!', detail: 'Hubo un error al obtener las firmas, intente de nuevo más tarde'});
    });
  }

  ObtenerDocumentoPagare(xICodigo: number, sNumRadic: string) {
    this.SpinnerService.show("sp6");
    let requestSolPagare: SolicitudPagare;
    requestSolPagare = { ICodigo: xICodigo }
    this.gestorSolPagareImpServie.obtenerDocumentoPagare(requestSolPagare).then(
      (data: ResponseObtenerDocumento) => {
        if (data != null && data != "ERROR" && data.respuestaBase != null && data.respuestaBase.codigo == 1) {
          this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Exito!', detail: data.respuestaBase.mensaje });
          var link = document.createElement('a');
          link.download = 'Pagare_'+sNumRadic +'.pdf';
          link.href = 'data:application/octet-stream;base64,' + data.archivoBase64;
          link.click();
          this.SpinnerService.hide("sp6");
        } else {
          this.SpinnerService.hide("sp6");
          this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Alerta!', detail: data.respuestaBase.mensaje });
        }
      }
    ).catch((error)  => {
      this.SpinnerService.hide("sp6");
      this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Alerta!', detail: 'Hubo un error al obtener el documento, intente de nuevo más tarde'});
    });
  }

  abrirDialog(xTitulo: string, xMensaje: string, tipoDialog: string, res: boolean) {
    const dataC = {
      titulo: xTitulo,
      texto: xMensaje,
      tipoDialog,
      res

    };
    const dialogRef = this.dialog.open(DialogComponent, { data: dataC, panelClass: 'prueba' });
    dialogRef.afterClosed().subscribe(
      (result: any) => {
        if (dataC.res === true) {

        }
      }
    );
  }


  crearPagares(personaDeudor: PersonaRe) {
    this.SpinnerService.show('sp6');
    personaDeudor.CNombres = personaDeudor.CNombres.toUpperCase();
    personaDeudor.CApellidos = personaDeudor.CApellidos.toUpperCase();
    personaDeudor.CDireccionResidencia = personaDeudor.CDireccionResidencia.toUpperCase();
    let requestCreaSolPagare: RequestCrearPagare = new RequestCrearPagare();
    requestCreaSolPagare.iTipoPerfilDeudor = this.tipoPerfil;
    requestCreaSolPagare.personaDeudor = personaDeudor;
    requestCreaSolPagare.iTipoPerfilCodeudor = this.tipoPerfilCod.slice();
    requestCreaSolPagare.personaCodeudor = this.personaCodeudor.slice();
    requestCreaSolPagare.requiereCodeudor = this.reqCodeudor;
    requestCreaSolPagare.ciudadCodeudor = this.cCiudadCod.slice();
    requestCreaSolPagare.ciudadDeudor = personaDeudor.CCiudad.ICodigo;
    requestCreaSolPagare.numPagare = this.numPagare;
    this.gestorSolPagareImpServie.saveSolicitudPagare(requestCreaSolPagare).then(
      (data:any) => {
        this.SpinnerService.hide('sp6');
        if (data.bRta) {
            this.SpinnerService.hide('sp6');
            // this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Excelente!', detail: 'Se agregó la solicitud de pagaré' });
            this.cerrarModal();
            this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Excelente!', detail: data.sMsj });
            this.messageService.add({ key: 'msgDiaglogValident', severity: 'success', summary: '¡Excelente!', detail: data.sMsj });
            this.cargarTableSolPagare();
        } else {
          this.SpinnerService.hide('sp6');
          // this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Alerta!', detail: data.sMsj });
          this.messageService.add({ key: 'msgDiaglogValident', severity: 'error', summary: '¡Alerta!', detail: data.sMsj });
        }
      }
    )
  }


  ReenviarOTP(xICodigo: number) {
    this.SpinnerService.show('sp6');
    let requestSolPagare: SolicitudPagare;
    requestSolPagare = { ICodigo: xICodigo };
    this.gestorSolPagareImpServie
      .reenviarOTPSolPagare(requestSolPagare)
      .then((data: RespuestaGenerica) => {
        if (typeof(data) === 'string') {
          this.SpinnerService.hide('sp6');
          this.messageService.add({
            key: 'msgToast',
            severity: 'warn',
            summary: '¡Alerta!',
            detail: 'El asociado no ha iniciado el proceso de firma.',
            life: 10000,
          });
        } else {
        if (data != null && data.bRta != null && data.bRta) {
          this.messageService.add({
            key: 'msgToast',
            severity: 'success',
            summary: '¡Exito!',
            detail: data.sMsj,
            life: 10000,
          });
          this.SpinnerService.hide('sp6');
        } else {
          this.SpinnerService.hide('sp6');
          this.messageService.add({
            key: 'msgToast',
            severity: 'error',
            summary: '¡Alerta!',
            detail: data.sMsj,
            life: 10000,
          });
        }
      }
      })
      .catch((error) => {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'error',
          summary: '¡Alerta!',
          detail:
            'Hubo un error al obtener el documento, intente de nuevo más tarde',
          life: 10000,
        });
      });
  }

  obtenerMultiplesDocs(xICodigo: number, sNumRadic: string){
    this.SpinnerService.show('sp6');
    let requestSolPagare: SolicitudPagare;
    requestSolPagare = { ICodigo: xICodigo };
    this.gestorSolPagareImpServie.obtenerMultiplesDocs(requestSolPagare).then((data: ResponseObtenerMultiplesDocs) => {
      if (
        data != null &&
        data != 'ERROR' &&
        data.respuesta.mensaje != null &&
        data.respuesta.codigo == 1
      ) {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'success',
          summary: '¡Exito!',
          detail: data.respuesta.mensaje,
        });
        for (const documento of data.documentos) {
          let link = document.createElement('a');
          link.download = `Documento${documento.nombre}` + sNumRadic + '.pdf';
          link.href = 'data:application/octet-stream;base64,' + documento.base64;
          link.click();
        }
      } else {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'error',
          summary: '¡Alerta!',
          detail: data.respuesta.mensaje,
        });
      }
    });
  }

  validarIdentidad() {
    this.SpinnerService.show("sp6");
    let xiCodUser = this.tokenServiceImp.getCodUserEmpleado();
    if (this.txtFechaExpDoc != null) {
      this.seguridadService.validarIdentidadCodeudor(xiCodUser,btoa(this.codeudor.ITipoIdent.ICodigo),btoa(this.codeudor.CIdentificacion), this.txtFechaExpDoc).subscribe(
        (data: RespuestaGenerica) => {
          this.SpinnerService.hide("sp6");
          if (data.bRta == false) {
            this.verificacion = false;
            this.messageService.add({key: 'msgDiaglogValident', severity: 'error', summary: '¡Alerta!', detail: data.sMsj });
          } else {
            this.verificacion = true;
            this.messageService.add({key: 'msgDiaglogValident', severity: 'success', summary: '¡Excelente!', detail: data.sMsj });
          }
        }
      ), error => {
        this.SpinnerService.hide("sp6");
        this.messageService.add({ key: 'msgDiaglogValident', severity: 'error', summary: '¡Alerta!', detail: 'Hubo un error al validar el documento, intente más tarde.' });
    }
    } else {
      this.SpinnerService.hide("sp6");
      this.messageService.add({ key: 'msgDiaglogValident', severity: 'error', summary: '¡Alerta!', detail: 'Debe ingresar la fecha de expedición del documento' });
    }
  }

  showModalNewInfoCodeudor() {
    this.tipoPerfilFind = null;
    this.tipoPerfil = null;
    this.listPerfilCiente = null;
    this.listTipoIdent = null;
    this.isAsociado = false;
    this.numPagare = null;
    this.informacion = false;
    this.reqCodeudor = false;
    this.ITipoIden = { ICodigo: 0, CDescripcion: '', CEstado: '' };
    this.ciudad = {ICodigo: 0, CNombres: ''};
    this.listaCiudades = [];
    this.codeudores = [];
    this.personaCodeudor = [];
    this.cCiudadCod = [];
    this.tipoPerfilCod = [];
    this.cCiudad = 0;
    this.infoPersona = { ICodigo: null, CIdentificacion: null, CNombres: null, CApellidos: null, CDireccionResidencia: null, CNumTelFijo: null, CNumCelular: null, CEmail: null, CGenero: null, CEstado: null, ITipoIdent: this.ITipoIden, CCiudad: this.ciudad };
    this.codeudor = { ICodigo: null, CIdentificacion: null, CNombres: null, CApellidos: null, CDireccionResidencia: null, CNumTelFijo: null, CNumCelular: null, CEmail: null, CGenero: null,CCiudad: null, TPerfil: null, CEstado: null, ITipoIdent: this.ITipoIden };
    this.txtFechaExpDoc = null;
    this.obtenerPerfilesClientes();
    this.obtenerCiudades();
    this.obtenerTiposIdent();
    this.displayModalInfoCodeudor = true;
  }

  cerrarModal() {
    this.tipoPerfilFind = null;
    this.tipoPerfil = null;
    this.listPerfilCiente = null;
    this.listTipoIdent = null;
    this.isAsociado = false;
    this.informacion = false;
    this.codeudores = [];
    this.personaCodeudor = [];
    this.numPagare = null;
    this.cCiudadCod = [];
    this.tipoPerfilCod = [];
    this.reqCodeudor = false;
    this.ITipoIden = { ICodigo: 0, CDescripcion: '', CEstado: '' };
    this.ciudad = {ICodigo: '0', CNombres: ''};
    this.listaCiudades = [];
    this.cCiudad = 0;
    let form = this.elRef.nativeElement.querySelector('.needs-validation');
    form.classList.remove('was-validated');
    this.infoPersona = { ICodigo: null, CIdentificacion: null, CNombres: null, CApellidos: null, CDireccionResidencia: null, CNumTelFijo: null, CNumCelular: null, CEmail: null, CGenero: null, CEstado: null, ITipoIdent: this.ITipoIden, CCiudad: this.ciudad };
    this.displayModalInfoCodeudor = false;
    this.messageService.clear();
  }

  reiniciarCampos() {
    this.tipoPerfilFind = null;
    this.tipoPerfil = null;
    this.listPerfilCiente = null;
    this.listTipoIdent = null;
    this.informacion = false;
    this.tipoIndent = null;
    this.Identi = null;
    this.numPagare = null;
    this.cCiudadCod = [];
    this.tipoPerfilCod = [];
    this.ITipoIden = { ICodigo: 0, CDescripcion: '', CEstado: '' };
    this.ciudad = {ICodigo: 0, CNombres: ''};
    this.listaCiudades = [];
    this.personaCodeudor = [];
    this.codeudores = [];
    this.cCiudad = 0;
    let forms = this.elRef.nativeElement.querySelector('.needs-validation');
    forms.classList.remove('was-validated');
    this.infoPersona = { ICodigo: null, CIdentificacion: null, CNombres: null, CApellidos: null, CDireccionResidencia: null, CNumTelFijo: null, CNumCelular: null, CEmail: null, CGenero: null, CEstado: null, ITipoIdent: this.ITipoIden, CCiudad: this.ciudad };
    this.obtenerPerfilesClientes();
    this.obtenerCiudades();
    this.obtenerTiposIdent();
  }

  findAsociado() {
    this.SpinnerService.show('sp6');
    this.messageService.clear();
    if (this.tipoPerfilFind !== null && this.tipoPerfilFind !== undefined && this.tipoIndent !== null && this.tipoIndent !== undefined && this.Identi !== null && this.Identi !== undefined) {
      this.cargarTodosUsuariosImpService.BuscarPersonaByIdentAndPerfil(this.tipoPerfilFind, btoa(this.tipoIndent), btoa(this.Identi)).then(
        (data: any) => {
          this.SpinnerService.hide('sp6');
          if (data != null && data.bRta) {
            this.informacion = true;
            this.messageService.add({key: 'msgDiaglogValident', severity: 'success', summary: '¡Excelente!', detail: data.sMsj });
            this.datosPersona = data.persona;
            this.tipoPerfil = this.tipoPerfilFind;
            this.infoPersona.CCiudad.ICodigo = this.datosPersona.cCiudad.iCodigo;
            this.infoPersona.CNombres = this.datosPersona.CNombres;
            this.infoPersona.CApellidos = this.datosPersona.CApellidos;
            this.infoPersona.ITipoIdent.ICodigo = this.datosPersona.ITipoIdent.ICodigo;
            this.infoPersona.CIdentificacion = this.datosPersona.CIdentificacion;
            this.infoPersona.CNumTelFijo = this.datosPersona.CNumTelFijo;
            this.infoPersona.CNumCelular = this.datosPersona.CNumCelular;
            this.infoPersona.CEmail = this.datosPersona.CEmail;
            this.infoPersona.CGenero = this.datosPersona.CGenero;
            this.infoPersona.CDireccionResidencia = this.datosPersona.CDireccionResidencia;
          } else {
            this.SpinnerService.hide('sp6');
            this.messageService.add({key: 'msgDiaglogValident', severity: 'error', summary: '¡Alerta!', detail: data.sMsj });
          }
        });
    } else {
      this.SpinnerService.hide('sp6');
      this.messageService.add({key: 'msgDiaglogValident', severity: 'error', summary: '¡Alerta!', detail: 'Por favor seleccione el tipo de identificación e ingrese el número de identificación a buscar como asociado.' });
    }
  }


  validarTexto(event: KeyboardEvent){
    const pattern = /[a-zA-ZáéíóúáéíóúñÁÉÍÓÚÑ ]/;
    if (pattern.test(event.key)) {
      return true;
    }
    return false;
  }

  validarNumeros(event: KeyboardEvent){
    const pattern = /^[0-9]/;
    if (pattern.test(event.key)) {
      return true;
    }
    return false;
  }

  validarIdentificacion(event: KeyboardEvent){
    const pattern = /^[a-zA-Z0-9]+/;
    if (pattern.test(event.key)) {
      return true;
    }
    return false;
  }

  validarCorreo(correo): boolean {
    let pattern = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
    if (correo.match(pattern)) {
      return true;
    } else {
      this.messageService.add({key: 'msgDiaglogValident', severity: 'error', summary: '¡Alerta!', detail: 'El email no es valido' });
      return false;
    }
  }

  mensaje(){
    this.messageService.add({key: 'msgDiaglogValident', severity: 'warn', summary: '¡Alerta!', detail: 'El formato de fecha de expedición es: día / mes / año' });
  }

  validarCampos() {
    if (
      this.tipoPerfil !== null && this.cCiudad !== null && this.cCiudad !== undefined
      && this.tipoPerfil !== undefined && this.infoPersona.CNombres !== null && this.infoPersona.CNombres.length > 3 && this.infoPersona.CNombres !== undefined
      && this.infoPersona.CApellidos !== null && this.infoPersona.CApellidos !== undefined && this.infoPersona.CApellidos.length > 3 && this.infoPersona.ITipoIdent.ICodigo !== undefined
      && this.infoPersona.CIdentificacion !== null && this.infoPersona.CIdentificacion !== undefined && this.infoPersona.CIdentificacion.length > 5 && this.infoPersona.CNumTelFijo !== undefined
      && this.infoPersona.CNumTelFijo !== null && this.infoPersona.CNumTelFijo.length === 7 && this.infoPersona.CNumCelular !== undefined && this.infoPersona.CNumCelular !== undefined && this.infoPersona.CNumCelular.length === 10
      && this.infoPersona.CEmail !== null && this.infoPersona.CEmail !== undefined && this.validarCorreo(this.infoPersona.CEmail) && this.infoPersona.CGenero !== undefined
      && this.infoPersona.CGenero !== null && this.infoPersona.CDireccionResidencia !== undefined && this.infoPersona.CDireccionResidencia !== null && this.infoPersona.CDireccionResidencia.length > 5
    ) {
      if (this.reqCodeudor && this.codeudores.length === 0) {
        this.messageService.add({key: 'msgDiaglogValident', severity: 'error', summary: '¡Alerta!', detail: 'Usted seleccionó que requiere codeudor, complete los campos y agregue el codeudor' });
      }
      else if (this.reqCodeudor && this.codeudores.length > 0) {
        // this.submitForm();
        this.crearPagares(this.infoPersona);
      }
      else if(this.reqCodeudor === false) {
        // this.submitForm();
        this.crearPagares(this.infoPersona);
      }
      else {
        this.validarCamposCodeudor();
      }
    }
    else if (this.isAsociado === true && this.informacion === false) {
      this.messageService.add({key: 'msgDiaglogValident', severity: 'error', summary: '¡Alerta!', detail: 'Debe buscar el usuario para poder guardar o agregué uno nuevo' });
    }
    else {
      let form = this.elRef.nativeElement.querySelector('#formInfoCodeudor');
      form.classList.add('was-validated');
      this.messageService.add({ key: 'msgDiaglogValident', severity: 'error', summary: '¡Alerta!', detail: 'Complete los campos marcados en rojo para continuar' });
    }
  }

  validarCamposCodeudor() {
    if (
      this.codeudor.TPerfil !== null && this.codeudor.CCiudad !== null && this.codeudor.CCiudad !== undefined
      && this.codeudor.TPerfil !== undefined && this.codeudor.CNombres !== null && this.codeudor.CNombres !== undefined && this.codeudor.CNombres.length > 3
      && this.codeudor.CApellidos !== null && this.codeudor.CApellidos !== undefined && this.codeudor.CApellidos.length > 3 && this.codeudor.ITipoIdent.ICodigo !== undefined
      && this.codeudor.CIdentificacion !== null && this.codeudor.CIdentificacion && this.codeudor.CIdentificacion.length > 5 && this.codeudor.CNumTelFijo !== undefined
      && this.codeudor.CNumTelFijo !== null && this.codeudor.CNumTelFijo.length === 7 && this.codeudor.CNumCelular !== undefined && this.codeudor.CNumCelular !== undefined && this.codeudor.CNumCelular.length === 10
      && this.codeudor.CEmail !== null && this.codeudor.CEmail !== undefined && this.codeudor.CGenero !== undefined && this.validarCorreo(this.codeudor.CEmail)
      && this.codeudor.CGenero !== null && this.codeudor.CDireccionResidencia !== undefined && this.codeudor.CDireccionResidencia !== null && this.codeudor.CDireccionResidencia.length > 5
      && this.txtFechaExpDoc !== null && this.txtFechaExpDoc !== undefined
    ) {
      this.agregarCodeudor();
      this.txtFechaExpDoc = null;
      return true;
    }
    else {
      let form = this.elRef.nativeElement.querySelector('#formInfoCodeudores');
      form.classList.add('was-validated');
      this.messageService.add({ key: 'msgDiaglogValident', severity: 'error', summary: '¡Alerta!', detail: 'Revise los campos y complete de manera correcta.' });
      return false;
    }
  }
  @HostListener('window:resize',['$event'])
  onResize(event:any){
    this.verificarAnchoPantalla();
  }

  verificarAnchoPantalla(){
    const screenWidth = window.innerWidth;
    this.mostrarTablaNormal = screenWidth > 540;
    this.mostrarTablaNueva = screenWidth <= 540;
  }

}
