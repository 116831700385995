<ngx-spinner name="sp6" type="timer" size="medium">
    <p style="color: white"> Espere un momento... </p>
</ngx-spinner>
<div class="encabezado">
    <h1 class="title">Mis Pagaré</h1>
</div>
<form class="login100-form was-validated" ngNativeValidate #infoCodeudor="ngForm" id="infoCodeudor" name="infoCodeudor"
    autocomplete="off">
    <div class="contenedor" id="contenedor">
    <p-toast position="top-right" key="msgToast"></p-toast>

    <!-- tabla para pantallas mas 540px -->
    <div class="card" *ngIf="mostrarTablaNormal">
        <p-toolbar styleClass="p-mb-5">
            <button pButton pRipple label="Cargar solicitudes" icon="pi pi-refresh" class="p-button-sm p-button-info"
                (click)="obtenerMisPagares()"></button>
        </p-toolbar>
        <p-table #dt [value]="listMisPagares" [rows]="10" [paginator]="true" dataKey="id"
            styleClass="p-datatable-responsive-demo"
            [globalFilterFields]="['iNumRadic','sNombreAsociado','sDecTipoEstadoSolPagare','sFechaSolPagare','sFechaInicioProceso','sFechaFinProceso']"
            currentPageReportTemplate="{first} a {last} de {totalRecords} pagaré" [showCurrentPageReport]="true"
             [rows]="10" [paginator]="true">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                    <h5 class="p-m-0">Mis pagaré</h5>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar..." class="amplio" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center; font-size: 14px;" pSortableColumn="iNumRadic">N° de Radicado <p-sortIcon field="iNumRadic"></p-sortIcon>
                    </th>
                    <th style="text-align: center; font-size: 14px;">Deudor
                    </th>
                    <th style="text-align: center; font-size: 14px;" pSortableColumn="sDecTipoEstadoSolPagare">Estado <p-sortIcon field="sDecTipoEstadoSolPagare">
                        </p-sortIcon>
                    </th>
                    <th style="text-align: center; font-size: 14px;">Fecha solicitud
                      <!-- <p-sortIcon field="sFechaSolPagare"> </p-sortIcon> -->
                    </th>
                    <th style="text-align: center; font-size: 14px;">Fecha inicio proceso
                      <!-- <p-sortIcon field="sFechaInicioProceso"></p-sortIcon> -->
                    </th>
                    <th style="text-align: center; font-size: 14px;">Fecha fin proceso
                      <!-- <p-sortIcon field="sFechaFinProceso"> </p-sortIcon> -->
                    </th>
                    <th style="text-align: center; font-size: 14px;">Acciones</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-pp>
                <tr>
                    <td style="text-align: center;">{{pp.iNumRadic}} </td>
                    <td style="text-align: center"> {{pp.sNombreAsociado}} </td>
                    <td style="text-align: center"> {{pp.sDecTipoEstadoSolPagare}} </td>
                    <td style="text-align: center"> {{pp.sFechaSolPagare}} </td>
                    <td style="text-align: center"> {{pp.sFechaInicioProceso}}</td>
                    <td style="text-align: center"> {{pp.sFechaFinProceso}} </td>
                    <td>
                        <button pButton pRipple icon="pi pi-play" label="Iniciar proceso" style="margin-bottom: 5%;"
                            class="p-button-sm p-button-rounded p-button-success p-button-outlined separacion"
                            (click)="goValidaIdentidad(pp)" *ngIf="pp.iCodTipoEstadoSolPagare==1">
                        </button>
                        <button pButton pRipple icon="pi pi-users" label="Firmas" style="margin-bottom: 5%;"
                            class="p-button-sm p-button-rounded p-button-secondary p-button-outlined separacion"
                            (click)="detalleFirmasPagare(pp.iCodigo)" *ngIf="pp.sFechaInicioProceso!==''">
                        </button>
                        <button pButton pRipple icon="pi pi-refresh" label="Actualizar" style="margin-bottom: 5%;"
                            class="p-button-sm p-button-rounded p-button-info  p-button-outlined separacion"
                            (click)="actualizarEstado(pp.iCodigo)" *ngIf="pp.sFechaInicioProceso!==''"></button>
                        <button pButton pRipple icon="pi pi-replay" label="Reenviar OTP" *ngIf="pp.sFechaInicioProceso!=='' && pp.iCodTipoEstadoSolPagare!==3"
                    class="p-button-sm p-button-rounded p-button-warning  p-button-outlined separacion"
                    (click)="ReenviarOTP(pp.iCodigo)"></button>
                        <button pButton pRipple icon="pi pi-download" label="Descargar" style="margin-bottom: 5%;"
                            *ngIf="pp.iCodTipoEstadoSolPagare==3"
                            class="p-button-sm p-button-rounded p-button-help  p-button-outlined separacion"
                            (click)="obtenerMultiplesDocs(pp.iCodigo,pp.iNumRadic)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="p-d-flex p-ai-center p-jc-between">
                    En total hay {{listMisPagares ? listMisPagares.length : 0 }} Pagaré.
                </div>
            </ng-template>
        </p-table>
    </div>
    <!-- tabla para pantallas max 540px -->
    <div class="card" *ngIf="mostrarTablaNueva">
      <p-toolbar styleClass="p-mb-5">
          <button pButton pRipple label="Cargar solicitudes" icon="pi pi-refresh" class="p-button-sm p-button-info"
              (click)="obtenerMisPagares()"></button>
      </p-toolbar>
      <p-table class="mi-tabla" #dt [value]="listMisPagares" [rows]="10" [paginator]="true" dataKey="id"
          styleClass="p-datatable-responsive-demo"
          [globalFilterFields]="['iNumRadic','sNombreAsociado','sDecTipoEstadoSolPagare','sFechaSolPagare','sFechaInicioProceso','sFechaFinProceso']"
          currentPageReportTemplate="{first} a {last} de {totalRecords} pagaré" [showCurrentPageReport]="true"
           [rows]="10" [paginator]="true">
          <ng-template pTemplate="caption">
              <div class="p-d-flex p-ai-center p-jc-between">
                  <h5 class="p-m-0">Mis pagaré</h5>
                  <span class="p-input-icon-left">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                          placeholder="Buscar..." class="amplio" />
                  </span>
              </div>
          </ng-template>
          <ng-template pTemplate="header">
              <tr>
                  <th style="text-align: center; font-size: 12px;" pSortableColumn="iNumRadic">N° de Radicado <p-sortIcon field="iNumRadic"></p-sortIcon>
                  </th>
                  <th style="text-align: center; font-size: 12px;">Deudor
                  </th>
                  <th style="text-align: center; font-size: 12px;" pSortableColumn="sDecTipoEstadoSolPagare">Estado <p-sortIcon field="sDecTipoEstadoSolPagare">
                      </p-sortIcon>
                  </th>
                  <th style="text-align: center; font-size: 12px;">Fecha solicitud
                    <!-- <p-sortIcon field="sFechaSolPagare"> </p-sortIcon> -->
                  </th>
                  <th style="text-align: center; font-size: 12px;">Fecha inicio proceso
                    <!-- <p-sortIcon field="sFechaInicioProceso"></p-sortIcon> -->
                  </th>
                  <th style="text-align: center; font-size: 12px;">Fecha fin proceso
                    <!-- <p-sortIcon field="sFechaFinProceso"> </p-sortIcon> -->
                  </th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-pp>
              <tr>
                  <td class="contenido-celda" style="text-align: center;">{{pp.iNumRadic}} </td>
                  <td class="contenido-celda" style="text-align: center"> {{pp.sNombreAsociado}} </td>
                  <td class="contenido-celda" style="text-align: center"> {{pp.sDecTipoEstadoSolPagare}} </td>
                  <td class="contenido-celda" style="text-align: center"> {{ pp.sFechaSolPagare | date:'dd/MM/yyyy HH:mm:ss' }} </td>
                  <td class="contenido-celda" style="text-align: center"> {{pp.sFechaInicioProceso | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                  <td class="contenido-celda" style="text-align: center"> {{pp.sFechaFinProceso | date:'dd/MM/yyyy HH:mm:ss'}} </td>
              </tr>
              <tr class="botones">
                  <td>
                      <button pButton pRipple icon="pi pi-play" style="margin-bottom: 5%;"
                          class="p-button-sm p-button-square p-button-success p-button-outlined separacion boton-cuadrado"
                          (click)="goValidaIdentidad(pp)" *ngIf="pp.iCodTipoEstadoSolPagare==1">
                      </button>
                    </td>
                      <td>
                        <button pButton pRipple icon="pi pi-users" style="margin-bottom: 5%;"
                            class="p-button-sm p-button-square p-button-secondary p-button-outlined separacion boton-cuadrado"
                            (click)="detalleFirmasPagare(pp.iCodigo)" *ngIf="pp.sFechaInicioProceso!==''">
                        </button>
                      </td>
                      <td>
                        <button pButton pRipple icon="pi pi-refresh" style="margin-bottom: 5%;"
                            class="p-button-sm p-button-square p-button-info  p-button-outlined separacion boton-cuadrado"
                            (click)="actualizarEstado(pp.iCodigo)" *ngIf="pp.sFechaInicioProceso!==''"></button>
                      </td>
                      <td>
                        <button pButton pRipple icon="pi pi-replay" *ngIf="pp.sFechaInicioProceso!=='' && pp.iCodTipoEstadoSolPagare!==3"
                        class="p-button-sm p-button-square p-button-warning  p-button-outlined separacion boton-cuadrado"
                        (click)="ReenviarOTP(pp.iCodigo)"></button>
                      </td>
                      <td>
                        <button pButton pRipple icon="pi pi-download" style="margin-bottom: 5%;"
                        *ngIf="pp.iCodTipoEstadoSolPagare==3"
                        class="p-button-sm p-button-square p-button-help  p-button-outlined separacion boton-cuadrado"
                        (click)="obtenerMultiplesDocs(pp.iCodigo,pp.iNumRadic)"></button>
                      </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="summary">
              <div class="p-d-flex p-ai-center p-jc-between">
                  En total hay {{listMisPagares ? listMisPagares.length : 0 }} Pagaré.
              </div>
          </ng-template>
      </p-table>
  </div>
  </div>
</form>
<!-- Dialog con detalle de firmas -->
<p-dialog header="Firmas de Pagaré" [(visible)]="displayModalFirmas" [style]="{width: '450vw'}" [baseZIndex]="10000"
    [blockScroll]="false" [draggable]="false" [resizable]="true" [maximizable]="true">
    <p-table [value]="listFirmas" [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
            Firmas del Pagaré
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="text-align: center;">N° de Identificación</th>
                <th style="text-align: center;">Nombres</th>
                <th style="text-align: center;">Estado Firma</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-f>
            <tr>
                <td style="text-align: center;">{{f.identificacion}}</td>
                <td style="text-align: center;">{{f.nombreFirmante}}</td>
                <td style="text-align: center;">{{f.estadoFirma}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>
<!-- Dialog para validar identidad -->
<p-dialog header="Validación de identidad" [(visible)]="displayModalValIdent" [style]="{width: '50vw'}"
    [baseZIndex]="10000" [blockScroll]="false" [draggable]="false" [resizable]="true">
    <form id="formValidarIdentidad" name="formValidarIdentidad" class="login100-form was-validated" ngNativeValidate
        #formValidarIdentidad="ngForm" autocomplete="off">
        <p-toast position="top-right" key="msgDiaglogValident"></p-toast>
        <div class="row">
            <p class="parrafo">
                Ingrese la fecha de expedición de su documento de identidad para realizar la validación.
            </p>
            <div class="col-lg-8 col-md-5 col-12">
                <div class="row">
                    <div class="col-12">
                        <input class="form-control inputLogin" type="date" name="txtFechaExpDoc" id="txtFechaExpDoc"
                            placeholder="Fecha de expedición" required="true" [(ngModel)]="txtFechaExpDoc" />
                    </div>
                </div>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="pi pi-times" iconPos="left" label="Cancelar"
            class="p-button-raised p-button-warning p-mr-4" style="color: white;" (click)="cancelarValidacion()"></button>
        <button type="submit" pButton pRipple icon="pi pi-check" style="color: white;" iconPos="left" label="Validar"
            class="p-button-raised p-button-primary p-mr-4" (click)="validarIdentidad()"></button>
    </ng-template>
</p-dialog>
<!-- Dialog para iniciar proceso de pagaré -->
<p-dialog header="Iniciar proceso firma pagaré" [(visible)]="displayModalInicioProceso" [style]="{width: '60vw'}"
    [baseZIndex]="10000" [blockScroll]="false" [draggable]="false" [resizable]="true">
    <form id="formValidarIdentidad" name="formValidarIdentidad" class="login100-form was-validated" ngNativeValidate
        #formValidarIdentidad="ngForm" autocomplete="off">
        <p-toast position="top-right" key="msgDiaglogProceso"></p-toast>
        <div class="row">
            <p class="parrafo">
                Para iniciar el proceso de firma Pagaré debe tener en cuenta que va llegar un SMS al número de celular
                registrado con la entidad y un email al correo electronico registrado en la entidad, tenga en cuenta que
                el remitente del correo es <strong>Olimpia IT.</strong>
            </p>
            <p class="parrafo">
                Tenga en cuenta que la vigencia del link o código OTP enviado es de 5 minutos.
            </p>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="pi pi-times" iconPos="left" label="Cancelar"
            class="p-button-raised p-button-warning p-mr-4" style="color: white;" (click)="cancelarProcesoPagare()"></button>
        <button type="submit" pButton pRipple icon="pi pi-check" iconPos="left" label="Iniciar"
            class="p-button-raised p-button-primary p-mr-4" (click)="generarPagare()"></button>
    </ng-template>
</p-dialog>
