<nav class="navbar navbar-expand-lg  bg-white  Menu ">
    <a class="navbar-brand" href="#">
        <img style="width: 100%;" class="logo" src="./assets/Imagenes/sucrediLogo.png" class="img" alt="" />
    </a>

    <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ml-auto mr-auto itemMenu">
            <li class="nav-item active">
                <p class="nav-link TextNegro" (click)="irInicio()">Inicio</p>
            </li>
            <li class="nav-item">
                <p class="nav-link TextNegro" (click)="goMisPagares()">Mis Pagaré</p>
            </li>
            <li class="nav-item">
                <i class="pi pi-user">
                    <p class="txtUsuario">{{nombre}}</p>
                    <p class="txtSesion" (click)="cerrarSesion()">Cerrar sesión</p>
                </i>
            </li>
        </ul>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
</nav>
<ngx-spinner name="sp6" type="timer" size="medium">
    <p style="color: white"> Espere un momento... </p>
</ngx-spinner>
<p-toast position="top-right" key="msgToast"></p-toast>
<p-toast position="center" key="cs" (onClose)="cancelarCierreSesion()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton (click)="confirmarCierreSesion()" label="¡Sí!"
                        class="p-button-success"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton (click)="cancelarCierreSesion()" label="¡NO!"
                        class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
